import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { ClassToggleService } from '@coreui/angular-pro';
import { throwError, catchError, map, BehaviorSubject, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { User } from '../../_models';

import {Logger} from './logger/logger.service';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    
    private userSubject: BehaviorSubject<User | null>;
    public user: Observable<User | null>;

    _log = inject(Logger);
    apiUrl = environment.authUrl

    classToggler = inject(ClassToggleService)
    
    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')!));
        console.log('AuthenticationService#constructor: userSubject', this.userSubject);
        this.user = this.userSubject.asObservable();
    }

    public get userValue() {
        return this.userSubject.value;
    }

    login(username: string, password: string) {

        var loginUrl = this.apiUrl + '/auth/login';


        this._log.info('Loading', loginUrl);
        const headerDict = {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'access_token':'fc3a7488-0217-4077-a5e5-b26ea0961964',
          'Access-Control-Allow-Headers': 'DNT,X-CustomHeader,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type:application/json; charset=UTF-8,auth'
       }
    
        const requestOptions = {
            headers: new HttpHeaders(headerDict), 
          };
      
        this._log.debug('Login.', loginUrl);
        return this.http.post<any>(loginUrl, { 'email': username, 'password': password }, requestOptions)
            .pipe(
                map(user => {
                          this._log.debug('*= *=*=*=*=*=*=*=*=*=*=*=*=success');
                          this._log.debug('login success', user, user.tracker);
                          this._log.debug('*= *=*=*=*=*=*=*=*=*=*=*=*=success');
                          if(user){
                                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                                    localStorage.setItem('user', JSON.stringify(user));
                                    localStorage.setItem('tracker', user.tracker);

                                    this.userSubject.next(user);
                                    // this.classToggler.toggle('body', 'dark-theme');
                                    return user;
                                    }
                            else{
                                throw new Error('Invalid username or password') 
                                }
                            }),
                    catchError(error => {
                        // Handle error here
                        console.error('Error occurred:', error);
                        throw new Error('Invalid username or password') 
                      })
                );
    }

    getme() {

      var meUrl = this.apiUrl + '/auth/me';


      this._log.info('Loading', meUrl);
      const headerDict = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'access_token':'fc3a7488-0217-4077-a5e5-b26ea0961964',
        


     }
  
      const requestOptions = {
          headers: new HttpHeaders(headerDict), 
        };
    
      this._log.info('Login.');
      return this.http.get<any>(meUrl,  requestOptions)
          .pipe(
              map(user => {
                        this._log.debug('*= *=*=*=*=*=*=*=*=*=*=*=*=success');
                        this._log.debug('login success', user);
                        this._log.debug('*= *=*=*=*=*=*=*=*=*=*=*=*=success');
                  }),
                  catchError(error => {
                      // Handle error here
                      this._log.error('Error occurred:', error);
                      throw new Error('Invalid username or password') 
                    })
              );
  }
    
    logout() {

        // TODO: call logout on server
        // remove user from local storage to log user out
        localStorage.removeItem('user');
        this.userSubject.next(null);

        this.router.navigate(['/']);
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          this._log.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          this._log.error(error);
          this._log.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        this._log.log(error)
        
        return throwError(() => 'An error occurred; please re-submit your request or try again later.\n \
                                if the problem persists, contact support support@sensori.ai');
      }
    
}